<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="markup-dialog">
      <div class="markup-dialog__header">
        <div class="markup-dialog__title">
          <div class="markup-dialog__icon-wrapper">
            <svg class="markup-dialog__trending-icon" viewBox="0 0 24 24" width="20" height="20">
              <path fill="currentColor" d="M16,6L18.29,8.29L13.41,13.17L9.41,9.17L2,16.59L3.41,18L9.41,12L13.41,16L19.71,9.71L22,12V6H16Z" />
            </svg>
          </div>
          <span>Definir Markup da Categoria</span>
        </div>
        <v-btn
          icon
          small
          @click="close"
          class="markup-dialog__close"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </div>

      <div class="markup-dialog__content-wrapper">
        <div class="markup-dialog__content">
          <div class="markup-dialog__section">
            <h3 class="markup-dialog__section-title">
              <div class="markup-dialog__info-icon">
                <svg viewBox="0 0 24 24" width="18" height="18">
                  <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,17H11V11H13V17M13,9H11V7H13V9Z" />
                </svg>
              </div>
              O que é Markup?
            </h3>
            <div class="markup-dialog__text">
              <p>O Markup é uma porcentagem estratégica que determina a margem de lucro desejada sobre o preço de custo de um produto ou serviço. É uma ferramenta essencial para garantir a rentabilidade do seu negócio.</p>
              <p>Por exemplo, se um produto custa R$ 100 e você define um Markup de 30%, o preço de venda será R$ 130, garantindo uma margem de lucro de R$ 30.</p>
              <div class="markup-dialog__formula">
                <div class="markup-dialog__formula-icon">
                  <svg viewBox="0 0 24 24" width="20" height="20">
                    <path fill="currentColor" d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M13,17H11V7H13V17M15,13H17V11H15V13M7,13H9V11H7V13Z" />
                  </svg>
                </div>
                <span class="markup-dialog__formula-item">Preço de Venda = Preço de Custo × (1 + Markup/100)</span>
              </div>
            </div>
          </div>

          <div class="markup-dialog__section">
            <h3 class="markup-dialog__section-title">
              <div class="markup-dialog__warning-icon">
                <svg viewBox="0 0 24 24" width="18" height="18">
                  <path fill="currentColor" d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                </svg>
              </div>
              Importante
            </h3>
            <div class="markup-dialog__text">
              <p>Para que o Markup funcione corretamente, é fundamental que todos os produtos tenham um preço de custo definido. Este valor é a base para o cálculo do preço de venda.</p>
              <p>Produtos sem preço de custo registrado manterão seus preços de venda inalterados, independentemente da definição do Markup.</p>
            </div>
          </div>

          <div class="markup-dialog__section">
            <h3 class="markup-dialog__section-title">
              <div class="markup-dialog__success-icon">
                <svg viewBox="0 0 24 24" width="18" height="18">
                  <path fill="currentColor" d="M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M10,17L5,12L6.41,10.59L10,14.17L17.59,6.58L19,8L10,17Z" />
                </svg>
              </div>
              Confirmação
            </h3>
            <div class="markup-dialog__text">
              <p>Você está prestes a definir o Markup de <strong>{{ markup }}%</strong> para todos os <strong>{{ itemsCount }} {{ itemTypeLabel }}</strong> desta categoria.</p>
              <div class="markup-dialog__warning">
                <div class="markup-dialog__error-icon">
                  <svg viewBox="0 0 24 24" width="18" height="18">
                    <path fill="currentColor" d="M12,2C6.48,2 2,6.48 2,12C2,17.52 6.48,22 12,22C17.52,22 22,17.52 22,12C22,6.48 17.52,2 12,2M13,17H11V15H13V17M13,13H11V7H13V13Z" />
                  </svg>
                </div>
                <div class="markup-dialog__warning-text">
                  Esta ação irá recalcular automaticamente o preço de venda de todos os itens baseado no novo Markup e seus respectivos preços de custo.
                  Para reverter esta alteração, será necessário ajustar manualmente cada item individualmente.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="markup-dialog__actions">
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="close"
          class="markup-dialog__btn markup-dialog__btn--cancel"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          @click="confirm"
          class="markup-dialog__btn markup-dialog__btn--confirm"
          :loading="loading"
        >
          Alterar Markup de {{ itemsCount }} {{ itemTypeLabel }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MarkupDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    markup: {
      type: Number,
      required: true
    },
    itemsCount: {
      type: Number,
      required: true
    },
    itemType: {
      type: String,
      default: 'item'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    itemTypeLabel() {
      switch (this.itemType) {
        case 'product':
          return 'produtos'
        case 'service':
          return 'serviços'
        default:
          return 'itens'
      }
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    confirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style scoped>
.markup-dialog {
  border-radius: 12px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
}

.markup-dialog__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-bottom: 1px solid #e0e0e0;
  background: linear-gradient(to right, #f8f9fa, #ffffff);
  position: sticky;
  top: 0;
  z-index: 1;
  flex-shrink: 0;
}

.markup-dialog__title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #1976d2;
}

.markup-dialog__icon-wrapper {
  background: #e3f2fd;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.markup-dialog__content-wrapper {
  flex: 1;
  overflow-y: auto;
  position: relative;
  min-height: 0;
}

.markup-dialog__content {
  padding: 20px;
}

.markup-dialog__section {
  margin-bottom: 32px;
  padding: 20px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.markup-dialog__section:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.markup-dialog__section:last-child {
  margin-bottom: 0;
}

.markup-dialog__section-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin-bottom: 16px;
}

.markup-dialog__info-icon,
.markup-dialog__warning-icon,
.markup-dialog__success-icon,
.markup-dialog__error-icon {
  margin-right: 8px;
  display: flex;
  align-items: center;
}

.markup-dialog__info-icon {
  color: #2196f3;
}

.markup-dialog__warning-icon {
  color: #ff9800;
}

.markup-dialog__success-icon {
  color: #4caf50;
}

.markup-dialog__error-icon {
  color: #f44336;
}

.markup-dialog__text {
  color: #555;
  line-height: 1.7;
}

.markup-dialog__text p {
  margin-bottom: 16px;
}

.markup-dialog__text p:last-child {
  margin-bottom: 0;
}

.markup-dialog__formula {
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  border-radius: 8px;
  padding: 16px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 12px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.markup-dialog__formula-icon {
  color: #1976d2;
}

.markup-dialog__formula-item {
  font-family: 'Roboto Mono', monospace;
  color: #1976d2;
  font-size: 15px;
  font-weight: 500;
}

.markup-dialog__warning {
  display: flex;
  align-items: flex-start;
  background: linear-gradient(135deg, #fff3e0 0%, #ffe0b2 100%);
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
  color: #e65100;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.markup-dialog__warning-text {
  flex: 1;
  line-height: 1.6;
}

.markup-dialog__actions {
  padding: 12px 20px;
  border-top: 1px solid #e0e0e0;
  background: linear-gradient(to right, #ffffff, #f8f9fa);
  position: sticky;
  bottom: 0;
  z-index: 1;
  flex-shrink: 0;
}

.markup-dialog__btn {
  min-width: 120px;
  font-weight: 500;
  text-transform: none;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
  height: 36px;
}

.markup-dialog__btn--cancel {
  color: #666;
}

.markup-dialog__btn--cancel:hover {
  background-color: #f5f5f5;
  transform: translateY(-1px);
}

.markup-dialog__btn--confirm {
  margin-left: 16px;
  box-shadow: 0 2px 8px rgba(25, 118, 210, 0.3);
}

.markup-dialog__btn--confirm:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(25, 118, 210, 0.4);
}

/* Animações */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.markup-dialog__section {
  animation: fadeIn 0.5s ease-out forwards;
}

.markup-dialog__section:nth-child(1) {
  animation-delay: 0.1s;
}

.markup-dialog__section:nth-child(2) {
  animation-delay: 0.2s;
}

.markup-dialog__section:nth-child(3) {
  animation-delay: 0.3s;
}
</style> 