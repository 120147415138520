import Product from '@/modules/register/config/Item/Product/Product'
import {ProductProfile} from '@/modules/register/config/Item/Product/ProductProfile'
import { Service } from '@/modules/register/config/Item/Service/Service'
import { ItemCode, ItemCodeGenerate, ItemCodePrint } from '@/modules/register/config/Item/Code/ItemCode'
import { ProductCategory } from '@/modules/register/config/Item/Product/ProductCategory'
import { ServiceCategory } from '@/modules/register/config/Item/Service/ServiceCategory'

import Customer from '@/modules/register/config/Person/Customer'
import { Collaborator, CollaboratorSelect } from '@/modules/register/config/Person/Collaborator'
import Company from '@/modules/register/config/Person/Company'
import Provider from '@/modules/register/config/Person/Provider'
import Transport from '@/modules/register/config/Person/Transport'
import Prospect from '@/modules/register/config/Person/Prospect'
import { ContactBusiness } from '@/modules/register/config/Person/ContactBusiness'
import ContactBusinessRead from '@/modules/register/config/Person/ContactBusinessRead'
import Info from '@/modules/register/config/Person/Info/Info'
import Address from '@/modules/register/config/Person/Address/Address'
import Segment from '@/modules/register/config/Person/Segment'
import Phone from '@/modules/register/config/Person/Phone/Phone'
import Cell from '@/modules/register/config/Person/Phone/Cell'
import { PersonFunction } from '@/modules/register/config/Person/PersonFunction'
import { PersonProfileImage } from '@/modules/register/config/Person/PersonProfileImage'
import { PersonFile } from '@/modules/register/config/Person/PersonFile'

import Team from '@/modules/register/config/Team/Team'
import TeamPerson from '@/modules/register/config/Team/TeamPerson'
import TeamNetwork from '@/modules/register/config/Team/TeamNetwork'
import TeamContactBusiness from '@/modules/register/config/Team/TeamContactBusiness'

import { ImportServicesExcel } from '@/modules/register/config/Imports/ImportServicesExcel'
import { ImportProductsExcel } from '@/modules/register/config/Imports/ImportProductsExcel'
import { ImportCustomerFromImanager } from '@/modules/register/config/Imports/ImportCustomerFromImanager'
import { CollaboratorResponsibleMigrate } from '@/modules/register/config/Person/CollaboratorResponsibleMigrate'

import { Channel } from '@/modules/register/config/Channel/Channel'

import { Tag } from '@/modules/register/config/Tag/Tag'

import { Account } from '@/modules/register/config/Account/Account'

import { WeekdayHour } from '@/modules/register/config/Weekday/WeekdayHour'

import { Import } from '@/default/config/Import/Import'

export default {
  'product': Product,
  'product-profile': new ProductProfile(),
  'product-code': new ItemCode(),
  'product-code-generate': new ItemCodeGenerate(),
  'product-code-print': new ItemCodePrint(),
  'service': new Service(),
  'customer': Customer,
  'collaborator': new Collaborator(),
  'collaborator-select': new CollaboratorSelect(),
  'company': Company,
  'provider': Provider,
  'transport': Transport,
  'prospect': Prospect,
  'contact': new ContactBusiness(),
  'contact-business-busca': ContactBusinessRead,
  'info': Info,
  'address': Address,
  'segment': Segment,
  'phone': Phone,
  'cell': Cell,
  'customer-function': new PersonFunction('customer', 'Cliente'),
  'collaborator-function': new PersonFunction('collaborator', 'Colaborador'),
  'company-function': new PersonFunction('company', 'Empresa'),
  'provider-function': new PersonFunction('provider', 'Fornecedor'),
  'prospect-function': new PersonFunction('prospect', 'Prospect'),
  'person-profile-image': new PersonProfileImage(),
  'person-file': new PersonFile(),
  'team': Team,
  'team-person': TeamPerson,
  'team-network': TeamNetwork,
  'team-contact-business': TeamContactBusiness,
  'channel': new Channel(),
  'tag': new Tag(),
  'account': new Account(),
  'import-product-excel': new ImportProductsExcel(),
  'import-service-excel': new ImportServicesExcel(),
  'import-customer-from-imanager': new ImportCustomerFromImanager(),
  'collaborator-responsible-migrate': new CollaboratorResponsibleMigrate(),
  'weekday-hour': new WeekdayHour(),
  'import': new Import(),
  'product-category': new ProductCategory(),
  'service-category': new ServiceCategory()
}
