<template>
  <div class="profile">
    <!-- Alerta de Negativação -->
    <div v-if="person.negatived_count > 0" class="profile__alert">
      <i class="material-icons">warning</i>
      <span>Negativado</span>
      <button class="profile__alert-button" @click="listFinancesNegatived()">Ver</button>
    </div>

    <!-- Cabeçalho compacto -->
    <div class="profile__header" :style="{ paddingBottom: person.type === 'Fisica' ? '0px' : '16px' }">
      <div class="profile__image-section">
        <div class="profile__image-wrapper" @click="handleImageClick">
          <img :src="(person.file && person.file.url) || getDefaultImage()" :alt="person.name" class="profile__image">
          <div v-if="person.file || updateProfileImage" class="profile__image-overlay">
            <i class="material-icons">{{ person.file ? 'zoom_in' : 'photo_camera' }}</i>
          </div>
          <div v-if="person.status" class="profile__status" :class="{'profile__status--active': person.status === 'active'}">
            <div class="profile__status-icon">
              <i class="material-icons">{{ person.status === 'active' ? 'radio_button_checked' : 'radio_button_unchecked' }}</i>
            </div>
            <span class="profile__status-text">{{ person.status === 'active' ? 'Ativo' : 'Inativo' }}</span>
          </div>
        </div>
      </div>
      
      <div class="profile__header-content">
        <div class="profile__main-info">
          <h1 class="profile__name">{{ person.name }}</h1>
          <h2 v-if="person.first_name && person.first_name !== person.name" class="profile__name">{{ person.first_name }}</h2>
          <div class="profile__tags">
            <span v-for="profile in person.profiles" :key="profile.name" class="profile__tag">
              <i class="material-icons">{{ profile.icon }}</i>{{ profile.title }}
            </span>
          </div>
        </div>

        <div class="profile__quick-info">
          <div class="profile__quick-column">
            <div class="profile__quick-item">
              <i class="material-icons">badge</i>
              <span>{{ person.type === 'Fisica' ? 'CPF' : 'CNPJ' }}:</span>
              <strong>{{ person.cpf_cnpj || '---' }}</strong>
            </div>
            <div class="profile__quick-item">
              <i class="material-icons">cake</i>
              <span>Nascimento:</span>
              <strong>{{ person.birthday_format || '---' }}</strong>
            </div>
            <div v-if="person.type === 'Fisica'" class="profile__quick-item">
              <i class="material-icons">work</i>
              <span>Profissão:</span>
              <strong>{{ person.profession || '---' }}</strong>
            </div>
            <div v-if="person.type === 'Juridica'" class="profile__quick-item">
              <i class="material-icons">language</i>
              <span>Site:</span>
              <strong>
                <a :href="person.site" target="_blank">{{ person.site }}</a>
              </strong>
            </div>
          </div>
          <div class="profile__quick-column">
            <div v-if="person.type === 'Fisica'" class="profile__quick-item">
              <i class="material-icons">fingerprint</i>
              <span>RG:</span>
              <strong>{{ person.rg ? `${person.rg}${person.rg_issuing_agency ? ` - ${person.rg_issuing_agency}` : ''}` : '---' }}</strong>
            </div>
            <div v-if="person.type === 'Juridica'" class="profile__quick-item">
              <i class="material-icons">fingerprint</i>
              <span>Inscrição Estadual:</span>
              <strong>{{ person.ie ? `${person.ie}` : '---' }}</strong>
            </div>
            <div v-if="person.type === 'Juridica'" class="profile__quick-item">
              <i class="material-icons">code</i>
              <span>Inscrição Municipal:</span>
              <strong>{{ person.im ? `${person.im}` : '---' }}</strong>
            </div>
            <div class="profile__quick-item">
              <i class="material-icons">elderly</i>
              <span>Idade:</span>
              <strong v-if="person.ages">{{ person.ages }} anos</strong>
              <strong v-else>---</strong>
            </div>
            <div v-if="person.type === 'Fisica'" class="profile__quick-item">
              <i class="material-icons">favorite</i>
              <span>Estado Civil:</span>
              <strong>{{ person.marital_status || '---' }}</strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Grid de informações compacto -->
    <div v-if="person.type === 'Fisica'" class="profile__additional">
      <button class="profile__toggle-btn" @click="showAdditional = !showAdditional">
        <i class="material-icons">{{ showAdditional ? 'expand_less' : 'expand_more' }}</i>
        Informações Adicionais
      </button>

      <div v-show="showAdditional" class="profile__grid">
        <div class="profile__info-block">
          <div class="profile__info-row">
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">school</i>
                <span>Escolaridade</span>
              </div>
              <strong>{{ person.education || '---' }}</strong>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">payments</i>
                <span>Renda</span>
              </div>
              <strong>{{ person.income_gross || '---' }}</strong>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">hub</i>
                <span>Origem</span>
              </div>
              <strong class="profile__origin">
                <i class="material-icons" :style="{color: person.origin.color}">{{ person.origin.icon }}</i>
                {{ person.origin.title }}
              </strong>
            </div>
          </div>

          <div class="profile__info-row">
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">public</i>
                <span>Nacionalidade</span>
              </div>
              <strong>{{ person.nationality || '---' }}</strong>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">location_city</i>
                <span>Naturalidade</span>
              </div>
              <strong>{{ person.naturalness || '---' }}</strong>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">history</i>
                <span>Cadastro</span>
              </div>
              <strong>{{ person.created_at | moment('DD/MM/YYYY') }}</strong>
            </div>
          </div>

          <div class="profile__info-row">
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">woman</i>
                <span>Mãe</span>
              </div>
              <strong>{{ person.mothers_name || '---' }}</strong>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">man</i>
                <span>Pai</span>
              </div>
              <strong>{{ person.fathers_name || '---' }}</strong>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">favorite</i>
                <span>Cônjuge</span>
              </div>
              <strong>{{ person.spouses_name || '---' }}</strong>
            </div>
          </div>

          <div v-if="person.profiles.map(profile => profile.name).includes('collaborator')" class="profile__info-row">
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">group</i>
                <span>Clientes deste colaborador</span>
              </div>
              <strong>
                <a href="#" @click="listCollaboratorCustomers()">
                  {{ person.customersCount || '0' }}
                </a>
              </strong>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-label">
                <i class="material-icons">swap_horiz</i>
                <span>Migrar clientes deste colaborador</span>
              </div>
              <strong>
                <a href="#" @click="migrateCollaboratorCustomers()">Migrar</a>
              </strong>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Componente de visualização de imagem -->
    <image-viewer
      v-if="person.file && person.file.url"
      v-model="showImageViewer"
      :image-url="person.file && person.file.url"
      :person-name="person.name"
      :update-profile-image="updateProfileImage"
      @change-image="openImageCropper"
      @clear-image="clearImage"
    />

    <!-- Componente de recorte de imagem -->
    <image-cropper 
      v-model="showImageCropper" 
      :person-id="person.id"
      :person-name="person.name"
      @success="onImageCropperSuccess"
    />
  </div>
</template>

<script>
import ImageViewer from './ImageViewer.vue'
import ImageCropper from './ImageCropper.vue'

export default {
  name: 'PersonInfo',
  components: {
    ImageViewer,
    ImageCropper
  },
  data() {
    return {
      store: null,
      showAdditional: false,
      showImageViewer: false,
      showImageCropper: false
    }
  },
  methods: {
    listFinancesNegatived: function () {
      this.$WiListDialog({
        wiConfig: 'finance-in-list',
        filter: {
          where: 'negatived,1',
          person_id: this.person.id
        },
        wiEditData: {
          person_id: this.person.id
        }
      })
    },
    listCollaboratorCustomers: function () {
      this.$WiListDialog({
        wiConfig: 'customer',
        filter: {
          where: 'responsible_id,' + this.person.id
        }
      })
    },
    migrateCollaboratorCustomers: function () {
      this.$WiEditDialog({
        wiConfig: 'collaborator-responsible-migrate',
        data: {
          responsible_id_origin: this.person.id
        },
        onSubmit: this.refresh
      })
    },
    handleImageClick() {
      if (this.person.file) {
        this.showImageViewer = true
      } else if (this.updateProfileImage) {
        this.openImageCropper()
      }
    },
    openImageCropper() {
      this.showImageCropper = true
    },
    onImageCropperSuccess(fileData) {
      this.person.file = fileData
      this.person.file_id = fileData.id
      this.$emit('onUpdateInfo', {
        info: {
          id: this.person.id,
          name: this.person.name,
          first_name: this.person.first_name,
          file_id: fileData.id
        },
        refresh: this.refresh
      })

    },
    clearImage() {
      this.$emit('onUpdateInfo', {
        info: {
          id: this.person.id,
          name: this.person.name,
          first_name: this.person.first_name,
          file_id: null
        },
        refresh: this.refresh
      })
    },
    getDefaultImage() {
      if (this.person.type === 'Fisica') {
        return `static/profile/${this.person.sex}-${this.person.status || 'active'}.png`
      }
      return `static/profile/juridica-${this.person.status == 'active' ? 'ativo' : 'inativo'}.png`
    }
  },
  created: function () {
    this.store = this.$route.name
  },
  props: {
    person: {
      required: true
    },
    refresh: {
      required: true
    },
    updateProfileImage: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.profile {
  background: #ffffff;
  border-radius: 8px;
  overflow: hidden;
}

.theme--dark .profile {
  background: #1E1E1E;
}

.profile__alert {
  background: #ff5252;
  color: white;
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
}

.profile__alert-button {
  margin-left: auto;
  background: transparent;
  border: 1px solid white;
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.profile__header {
  padding: 16px 16px 0 16px;
  display: flex;
  gap: 24px;
}

.profile__image-section {
  flex-shrink: 0;
}

.profile__image-wrapper {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  border: 3px solid #e0e0e0;
  transition: all 0.3s ease;
}

.theme--dark .profile__image-wrapper {
  border-color: #424242;
}

.profile__image-wrapper:hover {
  border-color: #1976d2;
}

.profile__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.profile__image-wrapper:hover .profile__image {
  transform: scale(1.1);
}

.profile__image-overlay {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.profile__image-overlay i {
  color: white;
  font-size: 24px;
}

.profile__image-wrapper:hover .profile__image-overlay {
  opacity: 1;
}

.profile__status {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(239, 83, 80, 0.95);
  color: white;
  padding: 8px 20px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.5px;
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 15px rgba(239, 83, 80, 0.3),
              inset 0 0 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  border: 2px solid rgba(255, 255, 255, 0.1);
  text-transform: uppercase;
}

.profile__status--active {
  background: rgba(67, 160, 71, 0.95);
  box-shadow: 0 4px 15px rgba(67, 160, 71, 0.3),
              inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

.profile__status-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
}

.profile__status i {
  font-size: 18px;
  animation: pulseIcon 2s infinite;
}

.profile__status-text {
  position: relative;
  padding-left: 4px;
}

.profile__status:hover {
  transform: translateX(-50%) translateY(-3px);
  padding: 8px 25px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25),
              inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}

.profile__status--active:hover {
  box-shadow: 0 6px 20px rgba(67, 160, 71, 0.4),
              inset 0 0 0 1px rgba(255, 255, 255, 0.2);
}

@keyframes pulseIcon {
  0% {
    opacity: 0.8;
    transform: scale(0.95);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0.8;
    transform: scale(0.95);
  }
}

/* Tema escuro */
.theme--dark .profile__status {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4),
              inset 0 0 0 1px rgba(255, 255, 255, 0.05);
}

.theme--dark .profile__status:hover {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5),
              inset 0 0 0 1px rgba(255, 255, 255, 0.1);
}

/* Responsivo */
@media (max-width: 768px) {
  .profile__status {
    padding: 6px 16px;
    font-size: 12px;
  }

  .profile__status-icon {
    width: 20px;
    height: 20px;
  }

  .profile__status i {
    font-size: 16px;
  }
}

.profile__header-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.profile__main-info {
  flex: 1;
}

.profile__quick-info {
  display: flex;
  gap: 24px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 12px;
}

.theme--dark .profile__quick-info {
  background: #2d2d2d;
}

.profile__quick-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile__quick-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  padding: 4px;
}

.profile__quick-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.theme--dark .profile__quick-item:hover {
  background: rgba(255, 255, 255, 0.05);
}

.profile__quick-item i {
  font-size: 18px;
  color: #757575;
  width: 24px;
}

.profile__quick-item span {
  color: #757575;
  min-width: 80px;
}

.profile__quick-item strong {
  margin-left: auto;
  font-weight: 500;
}

.profile__name {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
}

.profile__tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  margin-top: 4px;
}

.profile__tag {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 11px;
  background: #e3f2fd;
  color: #1976d2;
}

.theme--dark .profile__tag {
  background: #263238;
}

.profile__grid {
  padding: 16px;
}

.profile__info-block {
  background: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
}

.theme--dark .profile__info-block {
  background: #2d2d2d;
}

.profile__info-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}

.profile__info-row:not(:last-child) {
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.theme--dark .profile__info-row:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.06);
}

.profile__info-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-size: 13px;
  padding: 8px;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}

.theme--dark .profile__info-item {
  background: rgba(255, 255, 255, 0.02);
}

.profile__info-label {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #757575;
}

.profile__info-label i {
  font-size: 18px;
  color: #1976d2;
}

.profile__info-item strong {
  font-weight: 500;
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile__origin {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.profile__origin i {
  font-size: 16px;
}

@media (max-width: 768px) {
  .profile__header {
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 12px;
  }

  .profile__image-wrapper {
    width: 200px;
    height: 200px;
  }

  .profile__header-content {
    width: 100%;
    text-align: center;
  }

  .profile__name {
    font-size: 16px;
  }

  .profile__tags {
    justify-content: center;
    margin-top: 8px;
  }

  .profile__quick-info {
    flex-direction: column;
    gap: 8px;
    padding: 8px;
  }

  .profile__quick-column {
    gap: 4px;
  }

  .profile__quick-item {
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    padding: 8px;
    justify-content: space-between;
  }

  .theme--dark .profile__quick-item {
    background: rgba(255, 255, 255, 0.03);
  }

  .profile__quick-item i {
    font-size: 16px;
    width: 20px;
  }

  .profile__quick-item span {
    flex: 1;
    text-align: left;
    min-width: auto;
  }

  .profile__quick-item strong {
    text-align: right;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .profile__info-block {
    padding: 12px;
  }

  .profile__info-row {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .profile__info-item {
    padding: 10px;
    background: rgba(0, 0, 0, 0.03);
  }

  .theme--dark .profile__info-item {
    background: rgba(255, 255, 255, 0.03);
  }

  .profile__info-label {
    min-width: 130px;
  }

  .profile__info-label i {
    font-size: 16px;
  }
}

@media (max-width: 360px) {
  .profile__image-wrapper {
    width: 200px;
    height: 200px;
  }

  .profile__name {
    font-size: 15px;
  }

  .profile__quick-item {
    font-size: 12px;
  }
}

.profile__additional {
  margin-top: 8px;
}

.profile__toggle-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  background: transparent;
  border: none;
  color: #1976d2;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.theme--dark .profile__toggle-btn {
  color: #64b5f6;
}

.profile__toggle-btn:hover {
  background: rgba(25, 118, 210, 0.05);
}

.theme--dark .profile__toggle-btn:hover {
  background: rgba(100, 181, 246, 0.05);
}

.profile__toggle-btn i {
  font-size: 20px;
  transition: transform 0.3s ease;
}

.profile__grid {
  transition: all 0.3s ease;
}

.profile__info-block {
  padding: 8px;
}

.profile__info-row {
  gap: 8px;
}

.profile__info-item {
  font-size: 12px;
}

.profile__info-item i {
  font-size: 16px;
  width: 16px;
}

.profile__info-item span {
  min-width: 60px;
}

.profile__info-item strong {
  max-width: 80px;
}

@media (max-width: 360px) {
  .profile__info-item {
    font-size: 10px;
  }

  .profile__info-item i {
    font-size: 14px;
    width: 14px;
  }

  .profile__info-item span {
    min-width: 50px;
  }

  .profile__info-item strong {
    max-width: 60px;
  }
}
</style>