import Person from '@/modules/register/pages/Person/Person'
import Team from '@/modules/register/pages/Team/Team'
import ChannelStatisticsByCustomer from '@/modules/register/pages/Reports/ChannelStatisticsByCustomer'
import { PERMISSION_REGISTER_CHANNEL } from '@/default/constants/permissions'
import ItemsCategories from '@/modules/register/pages/ItemsCategories'

export default [
  {
    path: 'customer/:id',
    name: 'customer',
    component: Person,
    meta: {
      id: '549b7548',
      auth: true,
      title: 'Detalhes do cliente',
      icon: 'people_outline'
    }
  },
  {
    path: 'collaborator/:id',
    name: 'collaborator',
    component: Person,
    meta: {
      id: 'dc24170b',
      auth: true,
      title: 'Detalhes do Colaborador',
      icon: 'assignment_ind',
      permission: (to, user) => {
        return to.name === 'collaborator' && parseInt(to.params.id) === user.person.id
      }
    }
  },
  {
    path: 'provider/:id',
    name: 'provider',
    component: Person,
    meta: {
      id: '232e1dba',
      auth: true,
      title: 'Detalhes do Fornecedor',
      icon: 'local_atm'
    }
  },
  {
    path: 'contact/:id',
    name: 'contact',
    component: Person,
    meta: {
      id: '84b55ddf',
      auth: true,
      title: 'Detalhes do Contato',
      icon: 'contacts'
    }
  },
  {
    path: 'prospect/:id',
    name: 'prospect',
    component: Person,
    meta: {
      id: '2498ebe2',
      auth: true,
      title: 'Detalhes do Prospect',
      icon: 'perm_identity'
    }
  },
  {
    path: 'author/:id',
    name: 'author',
    component: Person,
    meta: {
      id: '572557ff',
      auth: true,
      title: 'Detalhes do Author',
      icon: 'portrait'
    }
  },
  {
    path: 'company/:id',
    name: 'company',
    component: Person,
    meta: {
      id: '8f5922e4',
      auth: true,
      title: 'Detalhes da Filial',
      icon: 'business'
    }
  },
  {
    path: 'team/:id',
    name: 'team',
    component: Team,
    meta: {
      id: 'ca5fa4b4',
      auth: true,
      title: 'Detalhes da Equipe',
      icon: 'group_add'
    }
  },
  {
    path: 'channel-statistics-by-customer',
    name: 'channel-statistics-by-customer',
    component: ChannelStatisticsByCustomer,
    meta: {
      id: PERMISSION_REGISTER_CHANNEL,
      auth: true,
      title: 'Clientes por canal',
      icon: 'equalizer'
    }
  },
  {
    path: 'items-categories',
    name: 'items-categories',
    component: ItemsCategories,
    meta: {
      id: '549b7548',
      auth: true,
      title: 'Categorias de Itens',
      icon: 'category'
    }
  },
  {
    path: 'items-categories/:itemType',
    name: 'items-categories',
    component: ItemsCategories,
    meta: {
      id: '549b7548',
      auth: true,
      title: 'Categorias de Itens',
      icon: 'category'
    }
  }
]