<template>
  <div class="item_categories elevation-20">
    <div class="item_categories__toolbar">
      <div class="item_categories__toolbar-left">
        <h1 class="item_categories__title">{{ pageTitle }}</h1>
        <span class="item_categories__count">{{ total }} categorias</span>
      </div>
      <div class="item_categories__toolbar-right">
        <v-btn icon small @click="refreshCategories" :loading="loading">
          <v-icon>refresh</v-icon>
        </v-btn>
        <div class="item_categories__search">
          <v-icon>search</v-icon>
          <input 
            type="text" 
            v-model="search"
            :placeholder="searchPlaceholder"
            class="item_categories__search-input"
            @input="handleSearch"
          >
          <button 
            v-if="search" 
            type="button"
            @click="clearSearch"
            class="item_categories__search-clear"
          >
            <v-icon small>close</v-icon>
          </button>
          <v-progress-circular
            v-if="searchLoading"
            indeterminate
            size="16"
            width="2"
            color="primary"
          ></v-progress-circular>
        </div>
        <button class="item_categories__add-button" @click="openNewCategory">
          <v-icon>add</v-icon>
          Nova Categoria
        </button>
      </div>
    </div>

    <div class="item_categories__content">
      <div class="item_categories__list" ref="listContainer" @scroll="handleScroll">
        <div class="item_categories__list-header">
          <div class="item_categories__list-header-name" @click="toggleSort('name')">
            <span>Nome</span>
            <v-icon small v-if="sortField === 'name'">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </v-icon>
          </div>
          <div class="item_categories__list-header-markup" @click="toggleSort('profit_markup')">
            <span>Markup</span>
            <v-icon small v-if="sortField === 'profit_markup'">
              {{ sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward' }}
            </v-icon>
          </div>
        </div>
        <div v-if="initialLoading" class="item_categories__initial-loading">
          <v-progress-circular
            indeterminate
            color="primary"
            size="24"
            width="2"
          ></v-progress-circular>
          <span>Carregando categorias...</span>
        </div>
        <template v-else>
          <transition-group name="list" tag="div">
            <div 
              v-for="category in categories" 
              :key="category.id"
              class="item_categories__item"
              :class="{ 'item_categories__item--selected': selectedCategory && selectedCategory.id === category.id }"
              @click="selectCategory(category)"
            >
              <div class="item_categories__item-info">
                <div class="item_categories__item-icon">
                  <v-icon>{{ getCategoryIcon(category.type) }}</v-icon>
                </div>
                <span class="item_categories__item-name">{{ category.name }}</span>
              </div>
              <div class="item_categories__item-markup">
                <v-icon small>trending_up</v-icon>
                {{ category.profit_markup }}%
              </div>
            </div>
          </transition-group>
          <div v-if="loading && !initialLoading" class="item_categories__loading">
            <v-progress-circular
              indeterminate
              color="primary"
              size="24"
              width="2"
            ></v-progress-circular>
            <span>Carregando mais...</span>
          </div>
          <div v-if="!loading && !hasMore && categories.length > 0" class="item_categories__end">
            <v-icon>check_circle</v-icon>
            <span>Todas as categorias carregadas</span>
          </div>
          <div v-if="!loading && categories.length === 0" class="item_categories__empty">
            <v-icon>search_off</v-icon>
            <span>{{ search ? 'Nenhuma categoria encontrada' : 'Nenhuma categoria cadastrada' }}</span>
          </div>
        </template>
      </div>

      <div class="item_categories__details">
        <SelectedCategory
          v-if="selectedCategory"
          :category="selectedCategory"
          :itemType="itemType"
          @refresh="refreshCategories"
        />
        <div v-else class="item_categories__details-empty">
          <v-icon class="item_categories__details-empty-icon">inbox</v-icon>
          <p>Selecione uma categoria para ver os {{ itemTypeLabel }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { callApi } from '@/default/service/Api'
import { debounce } from 'lodash'
import SelectedCategory from './SelectedCategory.vue'

export default {
  name: 'ItemsCategories',
  components: {
    SelectedCategory
  },
  data() {
    return {
      categories: [],
      selectedCategory: null,
      search: '',
      currentPage: 1,
      total: 0,
      loading: false,
      searchLoading: false,
      hasMore: true,
      searchTimeout: null,
      initialLoading: false,
      sortField: 'order',
      sortDirection: 'asc'
    }
  },
  computed: {
    itemType() {
      return this.$route.params.itemType || 'item'
    },
    pageTitle() {
      switch (this.itemType) {
        case 1: 
        case 'product':
          return 'Categorias de Produtos'
        case 2:
        case 'service':
          return 'Categorias de Serviços'
        default:
          return 'Categorias de Itens'
      }
    },
    itemTypeLabel() {
      switch (this.itemType) {
        case 1:
        case 'product':
          return 'produtos'
        case 2:
        case 'service':
          return 'serviços'
        default:
          return 'itens'
      }
    },
    searchPlaceholder() {
      return this.categories.length > 0 
        ? `Buscar em ${this.total} categorias...`
        : 'Digite para buscar categorias...'
    }
  },
  methods: {
    getCategoryIcon(type) {
      switch (type) {
        case 1:
        case 'product':
          return 'inventory'
        case 2:
        case 'service':
          return 'miscellaneous_services'
        default:
          return 'category'
      }
    },
    getItemTypeLabel(type) {
      switch (type) {
        case 1:
        case 'product':
          return 'produto'
        case 2:
        case 'service':
          return 'serviço'
        default:
          return 'item'
      }
    },
    getItemsCategories(page = 1, searchTerm = '') {
      if (this.loading || !this.hasMore) return

      this.loading = true
      if (page === 1) {
        this.initialLoading = true
      }

      const endpoint = this.itemType === 'item' 
        ? 'register/category'
        : `register/${this.itemType}/category`

      callApi.get({
        uri: endpoint,
        params: {
          page,
          limit: 20,
          search: searchTerm,
          order: `${this.sortField},${this.sortDirection}`
        },
        sucess: (response) => {
          const { data, total, current_page, last_page } = response?.data || {}
          
          if (page === 1) {
            this.categories = data || []
          } else {
            this.categories = [...this.categories, ...(data || [])]
          }

          this.total = total || 0
          this.currentPage = current_page || 1
          this.hasMore = current_page < last_page
          this.loading = false
          this.initialLoading = false
        },
        error: (error) => {
          console.error('Erro ao carregar categorias:', error)
          this.loading = false
          this.initialLoading = false
        }
      })
    },
    handleSearch: debounce(function() {
      if (this.search.length >= 3 || this.search.length === 0) {
        this.searchLoading = true
        this.currentPage = 1
        this.hasMore = true
        this.getItemsCategories(1, this.search)
        this.searchLoading = false
      }
    }, 500),
    clearSearch() {
      this.search = ''
      this.refreshCategories()
    },
    refreshCategories() {
      this.search = ''
      this.currentPage = 1
      this.hasMore = true
      this.getItemsCategories(1)
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      const threshold = 100

      if (scrollHeight - scrollTop - clientHeight < threshold) {
        this.loadMore()
      }
    },
    loadMore() {
      if (!this.loading && this.hasMore) {
        this.getItemsCategories(this.currentPage + 1, this.search)
      }
    },
    selectCategory(category) {
      this.selectedCategory = category
    },
    openNewCategory() {
      this.$WiEditDialog({
        wiConfig: `${this.itemType}-category`,
        onSubmit: () => {
          this.getItemsCategories()
        }
      })
    },
    toggleSort(field) {
      if (this.sortField === field) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortField = field
        this.sortDirection = 'asc'
      }
      this.refreshCategories()
    },
    editCategory() {
      this.$WiEditDialog({
        wiConfig: `${this.itemType}-category`,
        data: this.selectedCategory,
        onSubmit: () => {
          this.getItemsCategories()
        }
      })
    },
    addExistingItem() {
      this.$WiListDialog({
        wiConfig: `${this.itemType}`,
        callback: (data) => {
          console.log(data)
          this.addItems(data)
        },
        mode: 'select',
      })
    },
    createNewItem() {
      this.$WiEditDialog({
        wiConfig: `${this.itemType}`,
        data: {
          item_category_id: this.selectedCategory.id
        },
        onSubmit: () => {
          this.$refs.itemsList?.getItems()
        }
      })
    },
    addItems(items) {
      console.log(items)
      let itemsArray = []
      if (Array.isArray(items)) {
        itemsArray = items.map(item => item.id)
      } else {
        itemsArray.push(items.id)
      }
      this.$WiApiPost({
        uri: `register/${this.itemType}/category/add-items`,
        data: {
          category_id: this.selectedCategory.id,
          items: itemsArray
        },
        config: {
          msgLoad: {
            title: `Adicionando itens...`,
            text: 'Não irá demorar mais que alguns segundos.'
          },
          msgSucess: {
            title: 'Itens adicionados com sucesso.',
            text: 'O resultado da solicitação irá aparecer na timeline.'
          },
          msgError: {
            title: 'Erro ao processar solicitação',
            text: 'Tente novamente ou contacte o suporte.'
          },
          msgAsk: {
            title: `Tem certeza que deseja adicionar ${itemsArray.length} ${this.getItemTypeLabel(this.itemType)}${itemsArray.length > 1 ? 's' : ''}?`,
            text: 'Esta ação só poderá ser desfeita manualmente.'
          }
        }
      })
    },
    setMarkup() {
      this.$WiEditDialog({
        wiConfig: `${this.itemType}-category-markup`,
        data: {
          id: this.selectedCategory.id,
          markup: this.selectedCategory.profit_markup
        },
        onSubmit: () => {
          this.getItemsCategories()
        }
      })
    }
  },
  mounted() {
    this.getItemsCategories()
  },
  watch: {
    itemType: {
      handler(newType, oldType) {
        if (newType !== oldType) {
          this.categories = []
          this.selectedCategory = null
          this.currentPage = 1
          this.hasMore = true
          this.getItemsCategories()
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.item_categories {
  height: calc(100vh - 88px);
  margin: 20px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  background-color: #f8f9fa;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.item_categories__toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e0e0e0;
}

.item_categories__toolbar-left {
  display: flex;
  align-items: baseline;
  gap: 12px;
}

.item_categories__title {
  font-size: 20px;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.item_categories__count {
  font-size: 14px;
  color: #666;
}

.item_categories__toolbar-right {
  display: flex;
  align-items: center;
  gap: 12px;
}

.item_categories__search {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 4px 8px;
  gap: 8px;
  position: relative;
  min-width: 200px;
  transition: all 0.2s ease;
}

.item_categories__search:focus-within {
  background-color: #fff;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.1);
}

.item_categories__search-input {
  border: none;
  background: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  color: #333;
}

.item_categories__search-input::placeholder {
  color: #999;
}

.item_categories__search-clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background: none;
  color: #666;
  opacity: 0.7;
  transition: all 0.2s ease;
  cursor: pointer;
  border-radius: 50%;
}

.item_categories__search-clear:hover {
  opacity: 1;
  color: #333;
  background-color: rgba(0, 0, 0, 0.05);
}

.item_categories__search-clear .v-icon {
  font-size: 16px;
}

.item_categories__add-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 14px;
}

.item_categories__add-button:hover {
  background-color: #1565c0;
}

.item_categories__content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.item_categories__list {
  width: 280px;
  background-color: white;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 0;
}

.item_categories__list-header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background-color: #f8f9fa;
  font-size: 12px;
  color: #666;
  font-weight: 500;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.item_categories__list-header-name,
.item_categories__list-header-markup {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.item_categories__list-header-name:hover,
.item_categories__list-header-markup:hover {
  color: #1976d2;
}

.item_categories__list-header-name {
  flex: 1;
  min-width: 0;
  margin-right: 8px;
}

.item_categories__list-header-markup {
  flex-shrink: 0;
  width: 60px;
  text-align: right;
}

.item_categories__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-bottom: 1px solid #f5f5f5;
  position: relative;
  overflow: hidden;
  min-width: 0;
}

.item_categories__item:hover {
  background-color: #f5f5f5;
  transform: translateX(4px);
}

.item_categories__item--selected {
  background-color: #e3f2fd;
  border-left: 3px solid #1976d2;
  transform: translateX(4px);
}

.item_categories__item-info {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-width: 0;
  margin-right: 8px;
}

.item_categories__item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #e3f2fd;
  border-radius: 4px;
  color: #1976d2;
  font-size: 16px;
  transition: all 0.2s ease-in-out;
}

.item_categories__item:hover .item_categories__item-icon {
  transform: scale(1.1);
  background-color: #1976d2;
  color: white;
}

.item_categories__item-name {
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.item_categories__item-markup {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 13px;
  color: #666;
  transition: all 0.2s ease-in-out;
  flex-shrink: 0;
  width: 60px;
  text-align: right;
}

.item_categories__item:hover .item_categories__item-markup {
  color: #1976d2;
  transform: translateX(-4px);
}

.item_categories__details {
  flex: 1;
  padding: 16px;
  background-color: white;
  overflow-y: auto;
  transition: all 0.3s ease-in-out;
}

.item_categories__details-header {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e0e0e0;
  transition: all 0.3s ease-in-out;
}

.item_categories__details-title {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
  transition: all 0.3s ease-in-out;
}

.item_categories__details-title h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
  transition: all 0.3s ease-in-out;
}

.item_categories__details-stats {
  display: flex;
  gap: 16px;
  transition: all 0.3s ease-in-out;
}

.item_categories__details-stat {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 8px 12px;
  transition: all 0.3s ease-in-out;
}

.item_categories__details-stat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background-color: #e3f2fd;
  border-radius: 4px;
  color: #1976d2;
}

.item_categories__details-stat-content {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.item_categories__details-stat-label {
  font-size: 12px;
  color: #666;
}

.item_categories__details-stat-value {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.item_categories__details-stat.markup .item_categories__details-stat-icon {
  background-color: #e8f5e9;
  color: #4caf50;
}

.item_categories__details-stat.items .item_categories__details-stat-icon {
  background-color: #e3f2fd;
  color: #1976d2;
}

.item_categories__details-empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #999;
  padding: 32px;
  transition: all 0.3s ease-in-out;
}

.item_categories__details-empty-icon {
  font-size: 48px;
  margin-bottom: 12px;
  color: #ccc;
  transition: all 0.3s ease-in-out;
}

.item_categories__details-add-product {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 14px;
  margin-top: 16px;
}

.item_categories__details-add-product:hover {
  background-color: #1565c0;
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.item_categories__initial-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 32px 16px;
  color: #1976d2;
}

.item_categories__initial-loading span {
  font-size: 14px;
}

.item_categories__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px;
  color: #1976d2;
  border-top: 1px solid #f5f5f5;
}

.item_categories__loading span {
  font-size: 14px;
}

.item_categories__end {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px;
  color: #4caf50;
  font-size: 14px;
}

.item_categories__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 32px 16px;
  color: #666;
  font-size: 14px;
}

.item_categories__empty .v-icon {
  font-size: 24px;
  color: #999;
}

/* Animações de transição */
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from {
  opacity: 0;
  transform: translateX(30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.list-move {
  transition: transform 0.3s ease;
}

/* Ajuste para o container de transição */
.list {
  display: flex;
  flex-direction: column;
}

.item_categories__details-actions {
  display: flex;
  gap: 8px;
  margin-left: auto;
  align-items: center;
}

.item_categories__details-title-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.item_categories__details-title-content h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.item_categories__details-title-content .v-icon {
  font-size: 24px;
  color: #1976d2;
}

@media (max-width: 768px) {
  .item_categories {
    margin: 12px;
  }

  .item_categories__toolbar {
    flex-direction: column;
    gap: 12px;
    padding: 12px;
  }

  .item_categories__toolbar-left {
    width: 100%;
    justify-content: space-between;
  }

  .item_categories__toolbar-right {
    width: 100%;
    flex-direction: column;
    gap: 8px;
  }

  .item_categories__search {
    min-width: 0;
    flex: 1;
  }

  .item_categories__add-button {
    width: 100%;
    justify-content: center;
  }

  .item_categories__content {
    flex-direction: column;
  }

  .item_categories__list {
    width: 100%;
    max-height: 40vh;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .item_categories__details {
    height: 60vh;
    padding: 12px;
  }

  .item_categories__details-header {
    padding-bottom: 12px;
    margin-bottom: 12px;
  }

  .item_categories__details-title h2 {
    font-size: 16px;
  }

  .item_categories__details-stats {
    flex-direction: column;
    gap: 8px;
  }

  .item_categories__details-empty {
    padding: 16px;
  }

  .item_categories__details-empty-icon {
    font-size: 36px;
  }

  .item_categories__details-add-product {
    width: 100%;
    justify-content: center;
  }

  .item_categories__list-header-markup {
    width: 50px;
  }

  .item_categories__initial-loading,
  .item_categories__loading,
  .item_categories__end,
  .item_categories__empty {
    padding: 12px;
  }

  .item_categories__details-actions {
    flex-direction: column;
    width: 100%;
    margin-top: 12px;
  }

  .item_categories__details-actions .v-btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .item_categories {
    margin: 8px;
  }

  .item_categories__title {
    font-size: 18px;
  }

  .item_categories__count {
    font-size: 12px;
  }

  .item_categories__list-header {
    padding: 6px 12px;
    font-size: 11px;
  }

  .item_categories__item {
    padding: 6px 12px;
  }

  .item_categories__item-icon {
    width: 24px;
    height: 24px;
    font-size: 14px;
  }

  .item_categories__item-name {
    font-size: 13px;
  }

  .item_categories__item-markup {
    font-size: 12px;
    width: 45px;
  }

  .item_categories__list-header-markup {
    width: 45px;
  }

  .item_categories__details-empty {
    padding: 16px;
  }

  .item_categories__details-empty-icon {
    font-size: 36px;
  }

  .item_categories__details-add-product {
    width: 100%;
    justify-content: center;
  }

  .item_categories__initial-loading,
  .item_categories__loading,
  .item_categories__end,
  .item_categories__empty {
    padding: 8px;
    font-size: 12px;
  }

  .item_categories__search {
    padding: 4px 6px;
  }
  
  .item_categories__search-input {
    font-size: 13px;
  }

  .item_categories__search-clear {
    width: 18px;
    height: 18px;
  }
  
  .item_categories__search-clear .v-icon {
    font-size: 14px;
  }
}
</style>