var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"selected_category"},[_c('div',{staticClass:"selected_category__header"},[_c('div',{staticClass:"selected_category__title"},[_c('div',{staticClass:"selected_category__title-content"},[_c('v-icon',[_vm._v(_vm._s(_vm.getCategoryIcon(_vm.category.type)))]),(!_vm.isSearching)?_c('h2',[_vm._v(_vm._s(_vm.category.name))]):_c('div',{staticClass:"selected_category__search"},[_c('div',{staticClass:"selected_category__search-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchQuery),expression:"searchQuery"}],ref:"searchInput",staticClass:"selected_category__search-input",attrs:{"type":"text","placeholder":"Buscar por nome..."},domProps:{"value":(_vm.searchQuery)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.searchQuery=$event.target.value},_vm.handleSearchInput],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSearchEnter($event)}}}),(_vm.searchQuery)?_c('button',{staticClass:"selected_category__search-clear",attrs:{"type":"button"},on:{"click":_vm.clearSearch}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("close")])],1):_vm._e()])])],1),(!_vm.isSearching)?_c('div',{staticClass:"selected_category__actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.loading},on:{"click":_vm.editCategory}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,3315064322)},[_c('span',[_vm._v("Editar Categoria")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.loading},on:{"click":_vm.setMarkup}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("trending_up")])],1)]}}],null,false,4091664243)},[_c('span',[_vm._v("Definir Markup")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.loading},on:{"click":_vm.refreshItems}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("refresh")])],1)]}}],null,false,1334843028)},[_c('span',[_vm._v("Atualizar Lista")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.toggleSearch}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("search")])],1)]}}],null,false,2218024118)},[_c('span',[_vm._v("Buscar")])]),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","loading":_vm.loading}},'v-btn',Object.assign({}, attrs, tooltipAttrs),false),Object.assign({}, on, tooltipOn)),[_c('v-icon',[_vm._v("add")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar "+_vm._s(_vm.getItemTypeLabel(_vm.category.type)))])])]}}],null,false,3875712686)},[_c('v-list',[_c('v-list-tile',{on:{"click":_vm.addExistingItem}},[_c('v-list-tile-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("link")]),_vm._v(" Adicionar "+_vm._s(_vm.getItemTypeLabel(_vm.category.type))+"s Existentes ")],1)],1),_c('v-list-tile',{on:{"click":_vm.createNewItem}},[_c('v-list-tile-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("add_circle")]),_vm._v(" Criar Novo "+_vm._s(_vm.getItemTypeLabel(_vm.category.type))+" ")],1)],1)],1)],1)],1):_c('div',{staticClass:"selected_category__actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.toggleSearch}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("arrow_back")])],1)]}}])},[_c('span',[_vm._v("Voltar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.toggleSearch}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("search")])],1)]}}])},[_c('span',[_vm._v("Buscar")])])],1)]),_c('div',{staticClass:"selected_category__stats"},[_c('div',{staticClass:"selected_category__stat markup"},[_c('div',{staticClass:"selected_category__stat-icon"},[_c('v-icon',[_vm._v("trending_up")])],1),_c('div',{staticClass:"selected_category__stat-content"},[_c('span',{staticClass:"selected_category__stat-label"},[_vm._v("Markup")]),_c('span',{staticClass:"selected_category__stat-value"},[_vm._v(_vm._s(_vm.category.profit_markup))])])]),_c('div',{staticClass:"selected_category__stat items"},[_c('div',{staticClass:"selected_category__stat-icon"},[_c('v-icon',[_vm._v("inventory")])],1),_c('div',{staticClass:"selected_category__stat-content"},[_c('span',{staticClass:"selected_category__stat-label"},[_vm._v(_vm._s(_vm.getItemTypeLabel(_vm.category.type))+"s")]),_c('span',{staticClass:"selected_category__stat-value"},[_vm._v("0")])])])])]),_c('div',{staticClass:"selected_category__content"},[_c('items-list',{ref:"itemsList",attrs:{"items":_vm.items,"loading":_vm.loading,"has-more":_vm.hasMore},on:{"load-more":_vm.loadMore,"item-click":_vm.handleItemClick}})],1),_c('markup-dialog',{attrs:{"markup":_vm.category.profit_markup,"items-count":_vm.total,"item-type":_vm.itemType,"loading":_vm.loading},on:{"confirm":_vm.handleMarkupConfirm},model:{value:(_vm.showMarkupDialog),callback:function ($$v) {_vm.showMarkupDialog=$$v},expression:"showMarkupDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }